<template>
    <div class="session-wrapper">
        <div class="session-left"></div>
        <div class="session-right text-center">
            <div class="session-table-cell">
                <div class="session-content">
                    <div class="mb-6 flex justify-center">
                        <img :src="meta[domain].logo" class="img-responsive" height="45" />
                    </div>
                    <h2 class="mb-4">Forgot your password?</h2>
                    <p class="fs-14">
                        If you can't remember your password, please provide your email address and
                        we will send you a link which you may use to change your password.
                    </p>
                    <div class="mb-5">
                        <v-text-field
                            label="Email"
                            v-model="form.email"
                            :error="form.errors.has('email')"
                            :error-messages="form.errors.get('email')"
                            @input.native="form.errors.clear('email')"
                            @keyup.enter.native="send"
                            autofocus
                            required
                        ></v-text-field>
                        <div class="mt-4">
                            <v-btn
                                @click="send"
                                block
                                color="primary"
                                class="mb-2"
                                :loading="sending"
                                >Send</v-btn
                            >
                        </div>
                    </div>
                    <div>
                        <router-link to="/login">Back to login</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Form from '@/utils/form';
import Http from '@/utils/httpClient';

export default {
    data() {
        return {
            sending: false,
            form: new Form({
                email: null
            })
        };
    },
    computed: {
        ...mapGetters(['meta', 'domain'])
    },
    methods: {
        send() {
            this.sending = true;
            this.form.errors.clear();
            Http.post('password/email', this.form.payload())
                .then(({ data }) => {
                    this.sending = false;
                    this.form.reset();
                    this.$root.$snackbar.open({
                        text: data.message,
                        type: 'success'
                    });
                })
                .catch(({ response }) => {
                    this.sending = false;
                    this.form.errors.record(response.data);
                });
        }
    }
};
</script>
